import React, {useEffect, useState} from "react";
import BootstrapSelect from "../../../utils/bootstrapSelect";
import {Company} from "../../../models/companies";
import * as calendarTypes from "../../../constants/calendar";
import * as accessRights from "../../../constants/accessRight";
import * as api from "../../../adapters/calendar";
import {useFormik} from "formik";
import {
    getDayOption,
    getDayOptions,
    getISOWeeks,
    getMonthOption,
    getMonthOptions,
    range
} from "../../../constants/global";
import dtf from "../../../utils/locale";
import {getCalendarTypesChoice, getCalendarTypesChoices} from "../../../constants/calendar";
import * as Yup from "yup";
import SmallLoader from "../../../utils/loader/small";
import getUser from "../../../security/getUser";
import FormError from "../../../utils/formError";
import {APPLICATION_PDF} from "../../../adapters/xhr";
import FileSaver from "file-saver";
import SalarySelector from "../../salary/selector";
import {Salaries} from "../../../models/salary";

interface Interface {
    company: Company,
    sortBy: number,
    isTasks: boolean,
    presence: boolean,
    calendar: string,
    dt?: Date,
    view: string
}

const CalendarDownload:React.FC<Interface> = (props) => {

    const [initialValues, setInitialValues] = useState<any>({});
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({}));
    const [filterType, setFilterType] = useState<number>(0);
    const dt = props.dt || new Date();
    const [isOpen, setIsOpen] = useState<boolean>(false)


    useEffect(() => {
        let controller = new AbortController();

        let el = document.getElementById("offcanvasCalendarDownload")!

        el.addEventListener("shown.bs.offcanvas", open)
        el.addEventListener("hidden.bs.offcanvas", close)

        return () => {
            controller.abort();
            el.removeEventListener("shown.bs.offcanvas", open)
            el.removeEventListener("hidden.bs.offcanvas", close)
        }
    }, [isOpen])

    function open(){
        setIsOpen(true)
    }
    function close(){
        setIsOpen(false)
    }


    const TYPE_DAILY = 1;
    const TYPE_WEEKLY = 2;
    const TYPE_MONTHLY = 3;

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.downloadTest(values, {headers: [APPLICATION_PDF], responseType: 'arraybuffer'})
                .then(resp => {
                    let newBlob = new Blob([resp.data], { type: 'application/pdf;charset=utf-8' })
                    FileSaver.saveAs(newBlob, `planning-sivan-${values.year}-${values.week ? 'week' : 'month'}-${values.week || values.month}.pdf`)
                    formikHelpers.setSubmitting(false)
            })
                .catch(() => formikHelpers.setSubmitting(false))
        }
    })

    useEffect(() => {

        let fields:string[] = ['type', 'calendar', 'company'];
        let _initialValues: any = {};
        let _validationSchema: any = {};

        switch (getDefaultType(props.view).value){
            case TYPE_DAILY:
                fields.push('day', 'week', 'year', 'display', 'format');
                break;
            case TYPE_WEEKLY:
                fields.push('week', 'year', 'display', 'format');
                break;
            case TYPE_MONTHLY:
                fields.push('month', 'year');
                break;
        }

        switch (props.calendar)
        {
            case calendarTypes.CALENDAR_EVENTS_GLOBAL:
                fields.push('sortBy', 'presence', 'hideAbsence')
                if ([TYPE_DAILY, TYPE_WEEKLY].includes(getDefaultType(props.view).value)){
                    fields.push("isTasks")
                }
                break;
            case calendarTypes.CALENDAR_TASK:
                fields.push('sortBy')
                break;
        }


        fields.forEach(f => {
            switch (f)
            {
                case "type":
                    _initialValues.type = getDefaultType(props.view).value
                    _validationSchema.type = Yup.number().required('Veuillez séléctionner un type de période')
                    break;
                case "calendar":
                    _initialValues.calendar = props.calendar
                    _validationSchema.calendar = Yup.string().required('Veuillez séléctionner un planning')
                    break;
                case "company":
                    _initialValues.company = props.company.id;
                    _validationSchema.company = Yup.number().required()
                    break;
                case "day":
                    _initialValues.day = dtf(dt, 'i')
                    _validationSchema.day = Yup.number().required()
                    break;
                case "week":
                    _initialValues.week = dtf(dt, 'w')
                    _validationSchema.week = Yup.number().required()
                    break;
                case "month":
                    _initialValues.month = dtf(dt, 'M')
                    _validationSchema.month = Yup.number().required()
                    break;
                case "year":
                    _initialValues.year = dt.getFullYear().toString()
                    _validationSchema.year = Yup.number().required()
                    break;
                case "sortBy":
                    _initialValues.sortBy = props.sortBy
                    _validationSchema.sortBy = Yup.number().required()
                    break;
                case "isTasks":
                    _initialValues.isTasks = props.isTasks
                    _validationSchema.isTasks = Yup.boolean().required()
                    break;
                case "presence":
                    _initialValues.presence = false
                    _validationSchema.presence = Yup.boolean().required()
                    break;
                case "hideAbsence":
                    _initialValues.hideAbsence = false
                    _validationSchema.hideAbsence = Yup.boolean().required()
                    break;
                case "display":
                    _initialValues.display = 1
                    _validationSchema.display = Yup.number().required()
                    break;
                case "format":
                    _initialValues.format = 'Portrait'
                    _validationSchema.format = Yup.string().required()
                    break;
            }
        })

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }, [props, isOpen])

    return <div className="offcanvas offcanvas-end offcanvas-event" tabIndex={-1} id="offcanvasCalendarDownload" aria-labelledby="offcanvasCalendarDownloadLabel">
        <div className="offcanvas-header">
            <h5 id="offcanvasCalendarDownloadLabel">Télécharger le planning</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            <div className={'p-1 p-md-3'}>
                {isOpen && <form onSubmit={formik.handleSubmit}>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col">
                                <BootstrapSelect
                                    options={[
                                        {label: 'Jour', value: 1},
                                        {label: 'Semaine', value: 2},
                                        {label: 'Mois', value: 3},
                                    ]}
                                    value={getDefaultType(props.view)}
                                    label={'Télécharger par'}
                                    required={true}
                                    onChange={(c) => handleTypeChange(Number(c!.value))}
                                />
                                <FormError errors={formik.errors} touched={formik.touched} field={'type'}/>
                            </div>
                            {[1, 2].includes(formik.values.type) && <div className={'col'}>
                                <BootstrapSelect
                                    options={[
                                        {label: 'Tableau', value: 1},
                                        {label: 'Timeline', value: 2},
                                    ]}
                                    enableReinitialize={true}
                                    value={[
                                        {label: 'Tableau', value: 1},
                                        {label: 'Timeline', value: 2},
                                    ].find(c => c.value === formik.values.display)}
                                    label={'Format'}
                                    required={true}
                                    onChange={(c) => formik.setFieldValue('display', c?.value)}
                                />
                                <FormError errors={formik.errors} touched={formik.touched} field={'display'}/>
                            </div>}
                            {[1, 2].includes(formik.values.type) && <div className={'col'}>
                                <BootstrapSelect
                                    options={[
                                        {label: 'Portrait', value: 'Portrait'},
                                        {label: 'Landscape', value: 'Landscape'},
                                    ]}
                                    enableReinitialize={true}
                                    value={[
                                        {label: 'Portrait', value: 'Portrait'},
                                        {label: 'Landscape', value: 'Landscape'},
                                    ].find(c => c.value === formik.values.format)}
                                    label={'Affichage'}
                                    required={true}
                                    onChange={(c) => formik.setFieldValue('format', c?.value)}
                                />
                                <FormError errors={formik.errors} touched={formik.touched} field={'format'}/>
                            </div>}
                        </div>
                    </div>
                    <div className="row">
                        {formik.values.type === 1 && <div className="col mb-3">
                            <BootstrapSelect
                                label={'Jour'}
                                options={[...getDayOptions()]}
                                enableReinitialize={true}
                                value={getDayOption(dtf(dt, 'i'))}
                                onChange={(c) => formik.setFieldValue('day', c?.value)}
                                required={true}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'day'}/>
                        </div>}
                        {[1, 2].includes(formik.values.type) && <div className="col mb-3">
                            <BootstrapSelect
                                label={'Semaine'}
                                enableReinitialize={true}
                                options={range(1, getISOWeeks(dt.getFullYear())).map(week => ({
                                    label: String(week),
                                    value: String(week)
                                }))}
                                value={{label: dtf(dt, 'w'), value: dtf(dt, 'w')}}
                                onChange={(c) => formik.setFieldValue('week', c?.value)}
                                required={true}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'week'}/>
                        </div>}
                        {formik.values.type === 3 && <div className="col mb-3">
                            <BootstrapSelect
                                label={'Mois'}
                                enableReinitialize={true}
                                options={[...getMonthOptions()]}
                                value={getMonthOption(dtf(dt, 'M'))}
                                onChange={(c) => formik.setFieldValue('month', c?.value)}
                                required={true}
                            />
                        </div>}
                        <div className="col mb-3">
                            <BootstrapSelect
                                label={'Année'}
                                enableReinitialize={true}
                                options={range(dt.getFullYear() - 5, dt.getFullYear() + 5).map(y => ({
                                    label: String(y),
                                    value: y.toString()
                                }))}
                                value={{label: String(dt.getFullYear()), value: dt.getFullYear().toString()}}
                                onChange={(c) => formik.setFieldValue('year', c?.value)}
                                required={true}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'year'}/>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={'Type de planning'}
                            enableReinitialize={true}
                            options={getCalendarTypesChoices()}
                            value={getCalendarTypesChoice(props.calendar)}
                            onChange={c => handleCalendarChange(String(c!.value))}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'calendar'}/>
                    </div>
                    {formik.values.calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL && <>
                        <div className={'col-12 mb-3'}>
                            <BootstrapSelect
                                label={'Trier par'}
                                enableReinitialize={true}
                                options={[
                                    {label: 'Collaborateurs', value: calendarTypes.SORT_BY_JOB},
                                    {label: 'Emplacements', value: calendarTypes.SORT_BY_LOCALISATION},
                                    {label: 'Activités', value: calendarTypes.SORT_BY_ACTIVITY},
                                ]}
                                value={{label: calendarTypes.getSortByTypeChoice(props.sortBy || calendarTypes.SORT_BY_JOB).label, value: props.sortBy || calendarTypes.SORT_BY_JOB}}
                                onChange={c => formik.setFieldValue('sortBy', c?.value)}
                                required={true}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'sortBy'}/>
                        </div>
                        {formik.values.type !== TYPE_MONTHLY && <div className="col-12 mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input"
                                       name={"isTasks"}
                                       onChange={formik.handleChange}
                                       defaultChecked={props.isTasks}
                                       type="checkbox"
                                       role="switch"
                                       id="isTasks"/>
                                <label className="form-check-label" htmlFor="isTasks">
                                    Afficher les tâches
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'isTasks'}/>
                        </div>}
                        <div className="col-12 mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" name={"presence"} onChange={formik.handleChange} type="checkbox" role="switch" id="isPresence"/>
                                <label className="form-check-label" htmlFor="isPresence">
                                    Afficher le décompte des présences
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'presence'}/>
                        </div>
                        <div className="col-12 mb-3">
                            <div className="form-check form-switch">
                                <input className="form-check-input" name={"hideAbsence"} onChange={formik.handleChange} type="checkbox" role="switch" id="isHideAbsence"/>
                                <label className="form-check-label" htmlFor="isHideAbsence">
                                    Masquer les absences des collaborateurs
                                </label>
                            </div>
                            <FormError errors={formik.errors} touched={formik.touched} field={'presence'}/>
                        </div>
                    </>}

                    {formik.values.calendar === calendarTypes.CALENDAR_TASK && <div className={'col-12 mb-3'}>
                        <BootstrapSelect
                            label={'Trier par'}
                            options={[
                                {label: 'Collaborateurs', value: calendarTypes.SORT_BY_JOB},
                                {label: 'Groupe de tâche', value: calendarTypes.SORT_TASK_BY_GROUP},
                            ]}
                            enableReinitialize={true}
                            value={{label: 'Collaborateurs', value: calendarTypes.SORT_BY_JOB}}
                            onChange={c => formik.setFieldValue('sortBy', c?.value)}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'sortBy'}/>
                    </div>}
                    <div className="col-12 mb-3">
                        <BootstrapSelect
                            label={"Établissement"}
                            enableReinitialize={true}
                            required={true}
                            value={{label: props.company.title, value: props.company.id!}}
                            options={getUser().currentSalary.companies.map((c: Company) => ({
                                label: c.title,
                                value: c.id
                            }))}
                            onChange={c => formik.setFieldValue('company', c?.value)}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'company'}/>
                    </div>
                    <div className="col-12 mb-3">
                        <h4>
                            <i className={'bi bi-filter'}></i> Filtrer les collaborateurs
                        </h4>
                        <div className="col-12">
                            <div className="form-check">
                                <input className="form-check-input" value={1} onChange={e => handleFilterTypeChange(e)}
                                       defaultChecked={true} type="radio" name="filterType" id="filterType1"/>
                                <label className="form-check-label" htmlFor="filterType1">
                                    Aucun filtre
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={2} onChange={e => handleFilterTypeChange(e)}
                                       type="radio" name="filterType" id="filterType2"/>
                                <label className="form-check-label" htmlFor="filterType2">
                                    Filtrer par métiers
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" value={3} onChange={e => handleFilterTypeChange(e)}
                                       type="radio" name="filterType" id="filterType3"/>
                                <label className="form-check-label" htmlFor="filterType3">
                                    Filtrer par collaborateurs & équipes
                                </label>
                            </div>
                        </div>
                    </div>
                    {filterType === 2 && <div className={'col-12 mb-3'}>
                        <BootstrapSelect
                            label={'Type de métier'}
                            options={[
                                {label: 'Front office', value: '1'},
                                {label: 'Back office', value: '2'}
                            ]}
                            value={{label: 'Front office', value: 1}}
                            onChange={(c) => formik.setFieldValue('job', c?.value)}
                            required={true}
                        />
                    </div>}
                    {filterType === 3 && <div className={'col-12 mb-3'}>
                        <SalarySelector
                            multiple={true}
                            fetchOptions={true}
                            onChange={(salaries: Salaries) => formik.setFieldValue('salaries', salaries.map(salary => salary.id))}
                            companies={[formik.values.company]}
                            initiallyOpen={true}
                            teams={true}
                            accessRight={accessRights.DOWNLOAD_CALENDAR}
                        />
                    </div>}
                    {/*<div className="col-12 mb-3">*/}
                    {/*    <label htmlFor="">*/}
                    {/*        Format du fichier*/}
                    {/*    </label>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <input className="form-check-input" type="radio" name="flexRadioDefault"*/}
                    {/*               id="flexRadioDefault1" defaultChecked={true} />*/}
                    {/*            <label className="form-check-label" htmlFor="flexRadioDefault1">*/}
                    {/*                Pdf*/}
                    {/*            </label>*/}
                    {/*    </div>*/}
                    {/*    <div className="form-check">*/}
                    {/*        <input className="form-check-input" type="radio" name="flexRadioDefault"*/}
                    {/*               id="flexRadioDefault2" />*/}
                    {/*            <label className="form-check-label" htmlFor="flexRadioDefault2">*/}
                    {/*                Xlsx (Microsoft Excel)*/}
                    {/*            </label>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-12">
                        <button className="btn w-100 btn-light">
                            {formik.isSubmitting ? <SmallLoader/> : <><i
                                className={'bi bi-download'}></i> Télécharger</>}
                        </button>
                    </div>
                </form>}
            </div>
        </div>
    </div>

    function getDefaultType(view : string)
    {
        switch (view){
            case calendarTypes.RESOURCE_TIMELINE_DAY_VIEW:
            case calendarTypes.TIME_GRID_DAY_VIEW:
                return {label: 'Jour', value: 1};
            case calendarTypes.RESOURCE_TIMELINE_MONTH_VIEW:
            case calendarTypes.DAY_GRID_MONTH_VIEW:
                return {label: 'Mois', value: 3};
            default:
                return {label: 'Semaine', value: 2};
        }
    }

    function handleTypeChange(type: number) {

        const _initialValues: any = {...formik.values};
        const _validationSchema: any = {...validationSchema.fields};
        _initialValues.type = type;

        let fields: string[] = [];
        let removeFields: string[] = [];
        switch (type){
            case TYPE_DAILY:
                fields = ['year', 'week', 'day', 'display', 'format']
                removeFields = ['month']

                if (_initialValues.calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL){
                    fields.push('isTasks');
                }

                break;
            case TYPE_WEEKLY:
                fields = ['year', 'week', 'display', 'format']
                removeFields = ['month', 'day']

                if (_initialValues.calendar === calendarTypes.CALENDAR_EVENTS_GLOBAL){
                    fields.push('isTasks');
                }

                break;
            case TYPE_MONTHLY:
                fields = ['year', 'month']
                removeFields = ['week', 'day', 'display', 'format', 'isTasks']
                break;
        }

        for (let i in fields){
            switch (fields[i]) {
                case "day":
                    _initialValues.day = dtf(dt, 'i');
                    _validationSchema.type = Yup.number().required();
                    break;
                case "week":
                    _initialValues.week = dtf(dt, 'w');
                    _validationSchema.week = Yup.number().required();
                    break;
                case "month":
                    _initialValues.month = dtf(dt, 'M');
                    _validationSchema.month = Yup.number().required();
                    break;
                case "year":
                    _initialValues.year = dtf(dt, 'uuuu');
                    _validationSchema.year = Yup.number().required();
                    break;
                case "display":
                    _initialValues.display = _initialValues.display || 1;
                    _validationSchema.display = Yup.number().required();
                    break;
                case "format":
                    _initialValues.format = _initialValues.format || 'Portrait';
                    _validationSchema.format = Yup.string().required();
                    break;
                case "isTasks":
                    _initialValues.isTasks = _initialValues.isTasks || false;
                    _validationSchema.isTasks = Yup.boolean().required();
                    break;
            }
        }

        for (let i in removeFields){
           if (_initialValues.hasOwnProperty(removeFields[i])){
               delete _initialValues[removeFields[i]]
           }

           if (_validationSchema.hasOwnProperty(removeFields[i])){
               delete _validationSchema[removeFields[i]]
           }
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    function handleCalendarChange(calendar: string) {

        const _initialValues: any = {...formik.values};
        const _validationSchema: any = {...validationSchema.fields};
        _initialValues.calendar = calendar;

        let fields:string[] = [];
        let removeFields:string[] = [];

        switch (calendar){
            case calendarTypes.CALENDAR_EVENTS_GLOBAL:
                fields = ['sortBy', 'isTasks', 'presence'];
                break;
            case calendarTypes.CALENDAR_TASK:
                fields = ['sortBy'];
                removeFields = ['isTasks', 'presence'];
                break;
            default:
                removeFields = ['sortBy', 'isTasks', 'presence'];
                break;
        }

        for (let i in fields){
            switch (fields[i]) {
                case "sortBy":
                    _initialValues.sortBy = props.sortBy;
                    _validationSchema.sortBy = Yup.number().required();
                    break;
                case "isTasks":
                    _initialValues.isTasks = false;
                    _validationSchema.isTasks = Yup.boolean().required();
                    break;
                case "presence":
                    _initialValues.presence = false;
                    _validationSchema.presence = Yup.boolean().required();
                    break;
            }
        }

        for (let i in removeFields){
            if (_initialValues.hasOwnProperty(removeFields[i])){
                delete _initialValues[removeFields[i]]
            }

            if (_validationSchema.hasOwnProperty(removeFields[i])){
                delete _validationSchema[removeFields[i]]
            }
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    function handleFilterTypeChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        setFilterType(Number(e.target.value));
        let _initialValues = {...formik.values}
        let _validationSchema = {...validationSchema.fields}

        let fields: string[] = []
        let removed: string[] = []

        switch (Number(e.target.value)){
            case 1:
                removed.push('job', 'salaries')
                break;
            case 2:
                fields.push('job')
                removed.push('salaries')
                break;
            case 3:
                fields.push('salaries')
                removed.push('job')
                break;
        }

        removed.forEach(f => {
            if (_initialValues.hasOwnProperty(f)){
                delete _initialValues[f]
            }

            if (_validationSchema.hasOwnProperty(f)){
                delete _validationSchema[f]
            }
        })

        fields.forEach(f => {
            switch (f)
            {
                case 'salaries' :
                    _initialValues[f] = [];
                    _validationSchema[f] = Yup.array().of(Yup.number()).min(1);
                    break;
                case 'job' :
                    _initialValues[f] = "1";
                    _validationSchema[f] = Yup.string().required();
                    break;
            }
        })

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }
}

export default CalendarDownload;