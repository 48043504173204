import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import DatePicker from "react-datepicker";
import AbsenceBalance from "../../balance";
import SalarySelector from "../../../salary/selector";
import { setHours } from "date-fns";

import { Salary } from "../../../../models/salary";

import * as type from "../../../../constants/absence";
import * as accessRights from "../../../../constants/accessRight";
import { COMPLEMENT_AFTERNOON, COMPLEMENT_ALL_DAY, COMPLEMENT_MORNING } from "../../../../constants/absence";

import * as api from "../../../../adapters/absence";
import * as fileApi from "../../../../adapters/absence/file";
import * as actions from "../../../../actions/absence";
import { showAlertSuccess } from "../../../../actions/alert";
import { axiosError } from "../../../../actions/axios";

import { Granted } from "../../../../security/granted";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import validationClass from "../../../../utils/validationClass";
import FormError from "../../../../utils/formError";
import DateCustomInput from "../../../../utils/customInput";
import StyledDropzone from "../../../../utils/fileUpload";
import SmallLoader from "../../../../utils/loader/small";

import "react-datepicker/dist/react-datepicker.css";

interface Interface {
    handleSubmitSuccess: (data: any) => any,
    salary?: Salary
}

export const typesAvailableToComplementTime = [
    type.TYPE_CP,
    type.TYPE_RTT,
    type.TYPE_REPOS,
    type.TYPE_CSS,
    type.TYPE_FORM,
    type.TYPE_CHILD_DISEASE,
    type.TYPE_WORK_ACCIDENT,
    type.TYPE_TRAVEL_ACCIDENT,
    type.TYPE_PROTECTIVE_LAYOFF,
    type.TYPE_DISCIPLINARY_DISMISSAL,
    type.TYPE_UNJUSTIFIED
]

export const AbsenceAddForm: React.FC<Interface> = (props) => {
    const { handleSubmitSuccess, salary } = props
    const dispatch = useDispatch();

    const state = useSelector((state:RootStateOrAny) => state)

    let date = new Date()
    date.setHours(0, 0, 0, 0);
    
    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        type: Yup.number().required(),
        start: Yup.date().required(),
        end: Yup
            .date()
            .required()
            .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                const { start } = this.parent;
                return value && start <= value
            }),
        startComplement: Yup.number().required(),
        salary: Yup.number().required('Veuillez choisir un collaborateur'),
    }));
    const [initialValues, setInitialValues] = useState<any>({
        type: 1,
        start: date,
        startComplement: 1,
        end: new Date(date),
        salary: salary?.id
    });
    const [file, setFile] = useState<File>();
    const [eventPrecisions, setEventPrecisions] = useState<{label: string, value: number}[]>([]);

    const handleSubmit = (data: any) => {
        Promise.all([
            handleSubmitSuccess(data)
        ]).then(() => {
            dispatch(showAlertSuccess("Absence ajoutée"));
            formik.setSubmitting(false)
        })
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.create(values).then(data => {
                if (file) {
                    let formData = new FormData();
                    formData.append('file', file)
                    fileApi.add(formData, data.data.id).then((data) => handleSubmit(data.data))
                } else {
                    handleSubmit(data.data)
                }

                dispatch(actions.addAbsenceSuccess(data.data))
            }).catch(error => {
                dispatch(axiosError(error))
            })
        }
    })

    useEffect(() => {
        if (((Granted(accessRights.EDIT_ABSENCE, formik.values.salary) && formik.values.salary !== state.auth.user.currentSalary.id) || (Granted(accessRights.VALIDATE_SELF_ABSENCE, formik.values.salary) && Number(formik.values.salary) === state.auth.user.currentSalary.id))){
            let _fields = validationSchema.fields;
            _fields.status = Yup.number().required();
            setValidationSchema(Yup.object().shape({..._fields}))
        }
    }, [])

    const handleTypeChange = (t: number) => {
        let fields: string[] = ['type', 'start', 'end', 'salary'];

        const _validationSchema: any = {};
        const _initialValues: any = {};

        switch (t) {
            case type.TYPE_MALADIE:
                fields.push('lastDayIn', 'fileName')
                break;
            case type.TYPE_EVENT:
                fields.push('eventType', 'eventPrecision')
                break;
            default:
                fields.push('startComplement')
                if (formik.values.start.getTime() !== formik.values.end.getTime()){
                    fields.push('endComplement')
                }
        }

        if (((Granted(accessRights.EDIT_ABSENCE, formik.values.salary) && formik.values.salary !== state.auth.user.currentSalary.id) || (Granted(accessRights.VALIDATE_SELF_ABSENCE, formik.values.salary) && Number(formik.values.salary) === state.auth.user.currentSalary.id))) {
            fields.push('status')
        }

        for(let i in fields){
            switch (fields[i]) {
                case "type":
                    _validationSchema.type = Yup.number().required('Veuillez séléctionner une option')
                    _initialValues.type = t;
                    break;
                case "status":
                    _validationSchema.status = Yup.number().required('Veuillez renseigner le statut')
                    _initialValues.status = formik.values.status;
                    break;
                case "start":
                    _validationSchema.start = Yup.date().required('Veuillez préciser une date de début')
                    _initialValues.start = formik.values.start || new Date();
                    break;
                case "end":
                    _validationSchema.end = Yup.date().required('Veuillez préciser une date de fin')
                        .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                            const { start } = this.parent;
                            return value && start <= value
                        });
                    _initialValues.end = formik.values.end || new Date();
                    break;
                case "salary":
                    _validationSchema.salary = Yup.number().required('Veuillez séléctionner un collaborateur')
                    _initialValues.salary = formik.values.salary || null;
                    break;
                case "eventType":
                    _validationSchema.eventType = Yup.number().required("Veuillez séléctionner un type d'évènement")
                    _initialValues.eventType = formik.values.eventType || 1;
                    break;
                case "lastDayIn":
                    _validationSchema.lastDayIn = Yup.date().required("Veuillez préciser une date")
                    _initialValues.lastDayIn = formik.values.lastDayIn || new Date();
                    break;
                case "eventPrecision":
                    _validationSchema.eventPrecision = Yup.number().required("Veuillez préciser le type d'évènement")
                    _initialValues.eventPrecision = formik.values.eventPrecision || 1;
                    break;
                case "startComplement":
                    _validationSchema.startComplement = Yup.number().required("Veuillez séléctionner une option")
                    _initialValues.startComplement = formik.values.startComplement || COMPLEMENT_ALL_DAY;
                    if (_initialValues.startComplement !== COMPLEMENT_ALL_DAY){
                        _validationSchema.startComplementTime = Yup.date().required("Veuillez séléctionner une heure")
                        _initialValues.startComplementTime = formik.values.startComplementTime || formik.values.start
                    }
                    break;
                case "endComplement":
                    _validationSchema.endComplement = Yup.number().required("Veuillez séléctionner une option")
                    _initialValues.endComplement = formik.values.endComplement || COMPLEMENT_ALL_DAY;
                    if (_initialValues.endComplement !== COMPLEMENT_ALL_DAY){
                        _validationSchema.endComplementTime = Yup.date().required("Veuillez séléctionner une heure")
                        _initialValues.endComplementTime = formik.values.endComplementTime || formik.values.end
                    }
                    break;
            }
        }


        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const handleDateChange = (field: "start"|"end", value: Date) => {
        const _initialValues = {...formik.values}
        const _validationSchema = {...validationSchema.fields};

        _initialValues[field] = value;
        let removeFields:string[] = [];

        switch (formik.values.type) {
            case type.TYPE_CP:
            case type.TYPE_RTT:
            case type.TYPE_CSS:
            case type.TYPE_FORM:
            case type.TYPE_REPOS:
            case type.TYPE_CHILD_DISEASE:
            case type.TYPE_WORK_ACCIDENT:
            case type.TYPE_TRAVEL_ACCIDENT:
            case type.TYPE_PROTECTIVE_LAYOFF:
            case type.TYPE_DISCIPLINARY_DISMISSAL:
            case type.TYPE_UNJUSTIFIED:

                _initialValues.startComplement = _initialValues.startComplement || 1
                _validationSchema.startComplement = Yup.number().required();
                if ([COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(_initialValues.startComplement)){
                    _initialValues.startComplementTime = setHours(field === "start" ? value : formik.values.start, formik.values.startComplementTime.getHours());
                }

                if (_initialValues.start.getTime() !== _initialValues.end.getTime()){
                    _initialValues.endComplement = _initialValues.endComplement || 1
                    _validationSchema.endComplement = Yup.number().required();
                    if ([COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(_initialValues.endComplement)){
                        _initialValues.endComplementTime = setHours(field === "end" ? value : formik.values.end, formik.values.endComplementTime.getHours());
                    }
                    break;
                }

                removeFields = ["endComplement", "endComplementTime"]

                for (let f in removeFields) {
                    if (_initialValues.hasOwnProperty(removeFields[f])){
                        delete _initialValues[removeFields[f]];
                    }
                    if (_validationSchema.hasOwnProperty(removeFields[f])){
                        delete _validationSchema[removeFields[f]];
                    }
                }
                break;

            default:
                removeFields = ["endComplement", "endComplementTime", "startComplement", "startComplementTime"];
                for(let f in removeFields){
                    if (_initialValues.hasOwnProperty(removeFields[f])){
                        delete _initialValues[removeFields[f]];
                    }
                    if (_validationSchema.hasOwnProperty(removeFields[f])){
                        delete _validationSchema[removeFields[f]];
                    }
                }
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const handleComplementChange = (t: "start"|"end", value: number) => {
        const _initialValues = {...formik.values}
        const _validationSchema = {...validationSchema.fields};

        switch (t) {
            case "start":
                _initialValues['startComplement'] = value;
                switch (value){
                    case 1:
                        delete _initialValues.startComplementTime;
                        delete _validationSchema.startComplementTime;
                        break;
                    default:
                        _initialValues.startComplementTime = setHours(formik.values.start, 12);
                        _validationSchema.startComplementTime = Yup.date().required();
                        break;
                }
                break;
            case "end":
                _initialValues['endComplement'] = value;
                switch (value){
                    case 1:
                        delete _initialValues.endComplementTime;
                        delete _validationSchema.endComplementTime;
                        break;
                    default:
                        _initialValues.endComplementTime = setHours(formik.values.end, 12);
                        _validationSchema.endComplementTime = Yup.date().required();
                        break;
                }
                break;
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }


    const filterComplementChoices = (t: "start"|"end") => {
        switch (t) {
            case "start":
                if (formik.values.start.getTime() === formik.values.end.getTime()){
                    return type.getComplementChoices();
                }

                return type.getComplementChoices().filter(c => c.value !== COMPLEMENT_MORNING)
            case "end":
                return type.getComplementChoices().filter(c => c.value !== COMPLEMENT_AFTERNOON)
        }
    }

    return (
        <form onSubmit={formik.handleSubmit} className="p-3">
            {/* TYPE */}
            <div className="col-12 mb-3">
                <BootstrapSelect
                    onChange={(choice) => {
                        handleTypeChange(Number(choice!.value))
                    }}
                    className={validationClass(formik.errors, formik.touched, 'type')}
                    value={type.getTypeChoice(1)}
                    label={"Type d'absence"}
                    required={true}
                    options={type.getTypeChoices()} />
                <FormError errors={formik.errors} touched={formik.touched} field={'type'} />
            </div>

            {/* EVENT TYPE */}
            {formik.values.type === type.TYPE_EVENT &&
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        onChange={(choice) => {
                            formik.setFieldValue('eventType', choice!.value)
                            formik.setFieldValue('eventPrecision', null)
                            setEventPrecisions([...type.getEventPrecisionChoices(Number(choice!.value))])
                        }}
                        className={validationClass(formik.errors, formik.touched, 'eventType')}
                        label={"Type d'absence"}
                        required={true}
                        options={type.getEventTypeChoices()}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'eventType'} />
                </div>
            }

            {/* EVENT PRECISION */}
            {formik.values.type === type.TYPE_EVENT && formik.values.eventType &&
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        onChange={(e) => formik.setFieldValue('eventPrecision', e?.value)}
                        className={validationClass(formik.errors, formik.touched, 'eventPrecision')}
                        value={eventPrecisions.find(precision => precision.value === formik.values.eventPrecision)}
                        enableReinitialize={true}
                        label={"Précision"}
                        required={true}
                        options={eventPrecisions}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'eventPrecision'} />
                </div>
            }

            {/*LAST DAY IN*/}
            {formik.values.type === type.TYPE_MALADIE &&
                <div className="col-12 mb-3">
                    <DatePicker
                        onChange={(date) => {
                            formik.setFieldValue('lastDayIn', date)
                        }}
                        dateFormat={"E dd MMM uuuu"}
                        selected={formik.values.lastDayIn}
                        customInput={<DateCustomInput label={"Dernier jour de présence dans l'établissement"} />}
                        endDate={formik.values.start}
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'lastDayIn')}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'lastDayIn'} />
                </div>
            }

            {/*START*/}
            <div className="col-12 mb-3">
                <div className={'d-flex align-items-center'}>
                    <div className="col">
                        <DatePicker
                            dateFormat={"E dd MMM uuuu"}
                            selected={formik.values.start}
                            onChange={(date:Date) => {
                                handleDateChange('start', date);
                            }}
                            customInput={<DateCustomInput  label={'Date de début'} />}
                            selectsStart
                            startDate={formik.values.start}
                            endDate={formik.values.end}
                            title={'Date de début'}
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'start')}
                        />
                    </div>

                    {typesAvailableToComplementTime.includes(formik.values.type) &&
                        <div className={'col'}>
                            <BootstrapSelect
                                label={"Précision"}
                                options={filterComplementChoices('start')}
                                value={type.getComplementChoices()[0]}
                                required={true}
                                onChange={(choice) => handleComplementChange("start", Number(choice!.value))}
                            />
                        </div>
                    }
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'start'} />
                <FormError errors={formik.errors} touched={formik.touched} field={'startComplement'} />
            </div>

            {[COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(formik.values.startComplement) &&
                <div className={'col-12 mb-3'}>
                    <DatePicker
                        onChange={(date) => {
                            formik.setFieldValue('startComplementTime', date)
                        }}
                        dateFormat={"HH:mm"}
                        showTimeSelectOnly={true}
                        showTimeSelect={true}
                        selected={formik.values.startComplementTime}
                        customInput={<DateCustomInput label={formik.values.startComplement === COMPLEMENT_MORNING ? "Absent jusqu'à" : "Absent à partir de"} />}
                        endDate={formik.values.end}
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'startComplementTime')}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'startComplementTime'} />
                </div>
            }

            {/*END*/}
            <div className="col-12 mb-3">
                <div className="d-flex">
                    <div className="col">
                        <DatePicker
                            selected={formik.values.end}
                            dateFormat={"E dd MMM uuuu"}
                            onChange={(date:Date, event) => {
                                handleDateChange('end', date);
                            }}
                            customInput={<DateCustomInput label={'Date de fin'} />}
                            selectsEnd
                            startDate={formik.values.start}
                            endDate={formik.values.end}
                            minDate={formik.values.start}
                            title={'Date de fin'}
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'end')}
                        />
                    </div>

                    <div className="clearfix" />

                    {typesAvailableToComplementTime.includes(formik.values.type) && formik.values.start.getTime() !== formik.values.end.getTime() &&
                        <div className="col">
                            <BootstrapSelect
                                label={"Précision"}
                                options={filterComplementChoices('end')}
                                value={type.getComplementChoices()[0]}
                                required={true}
                                onChange={(choice) => handleComplementChange("end", Number(choice!.value))}
                            />
                        </div>
                    }
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'end'} />
                <FormError errors={formik.errors} touched={formik.touched} field={'endComplement'} />
            </div>

            {[COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(formik.values.endComplement) &&
                <div className={'col-12 mb-3'}>
                    <DatePicker
                        onChange={(date) => {
                            formik.setFieldValue('endComplementTime', date)
                        }}
                        dateFormat={"HH:mm"}
                        showTimeSelectOnly={true}
                        showTimeSelect={true}
                        popperPlacement={"top-end"}
                        showPopperArrow={false}
                        selected={formik.values.endComplementTime}
                        customInput={<DateCustomInput label={"Absent jusqu'à"} />}
                        endDate={formik.values.end}
                        className={'form-control' + validationClass(formik.errors, formik.touched, 'endComplementTime')}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'endComplementTime'} />
                </div>
            }

            {/*SALARY*/}
            {Granted(accessRights.EDIT_ABSENCE, formik.values.salary) &&
                <div className="col-12 mb-3">
                    <SalarySelector
                        onChange={(s: Salary) => formik.setFieldValue('salary', s.id)}
                        required={true}
                        value={salary}
                        fetchOptions={true}
                        accessRight={accessRights.EDIT_ABSENCE}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'salary'}/>
                </div>
            }

            {((Granted(accessRights.EDIT_ABSENCE, formik.values.salary) && formik.values.salary !== state.auth.user.currentSalary.id) || (Granted(accessRights.VALIDATE_SELF_ABSENCE, formik.values.salary) && Number(formik.values.salary) === state.auth.user.currentSalary.id)) &&
                <div className="col-12 mb-3">
                    <div className="col-12">
                        <BootstrapSelect
                            label={'Statut'}
                            options={type.getStatusChoices()}
                            enableReinitialize={true}
                            value={type.getStatusChoices()[formik.values.status - 1] || type.getStatusChoices()[0]}
                            onChange={(choice) => formik.setFieldValue('status', choice!.value)}
                            className={validationClass(formik.errors, formik.touched, 'status')}
                            required={true}
                        />
                        <FormError errors={formik.errors} touched={formik.touched} field={'status'}/>
                    </div>
                </div>
            }

            {/*FILE NAME*/}
            {formik.values.type === type.TYPE_MALADIE &&
                <div className="col-12 mb-3">
                    <label htmlFor="fileName">
                        Justificatif
                    </label>
                    <StyledDropzone handleUploadedFile={setFile} />
                    <FormError errors={formik.errors} touched={formik.touched} field={'fileName'} />
                </div>
            }

            <button type={'submit'} className='btn btn-light w-100' disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check-lg'}> </i> Ajouter</>}
            </button>

            {formik.values.type === type.TYPE_CP &&
                <AbsenceBalance
                    start={formik.values.start}
                    end={formik.values.end}
                    salary={formik.values.salary}
                />
            }
        </form>
    )
}

export default AbsenceAddForm;