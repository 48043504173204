import { get, post } from "../../xhr"

export function show(id: number) {
    return get(`admin/billing/${id}`);
}

export function linkSellsyIdToCompanyGroup(companyGroupId: number, data: { sellsyId: number }) {
    return post(`admin/billing/link_sellsy_id/company_group/${companyGroupId}`, data);
}

export function linkSellsyIdToCompany(companyId: number, data: { sellsyId: number }) {
    return post(`admin/billing/link_sellsy_id/company/${companyId}`, data);
}

export function createCompanySubscription(companyId: number, data: { subscriptionModelId: number, subscriptionStartDate: Date, billingStartDate: Date }) {
    return post(`admin/billing/subscriptions/create/${companyId}`, data);
}

export function updateCompanySubscription(companyId: number, data: { subscriptionModelId: number, subscriptionStartDate: Date, billingStartDate: Date }) {
    return post(`admin/billing/subscriptions/update/${companyId}`, data);
}

export function linkBillingCompanyToSalary(salaryId: number, companyId: number) {
    return get(`admin/billing/salaries/${salaryId}/link_billing_company/${companyId}`);
}