import React, {useEffect, useState} from "react";
import * as api from "../../../../adapters/template/resource";
import * as salaryApi from "../../../../adapters/salary";
import {Salaries, Salary} from "../../../../models/salary";
import Loader from "../../../../utils/loader";
import {Template} from "../../../../models/template";

interface Interface {
    template: Template,
    setTemplate: React.Dispatch<any>,
    handleSubmitSuccess: (ev: any, previous?: any) => any
}

const TemplateSalaries:React.FC<Interface> = (props) => {

    const [salaries, setSalaries] = useState<Salaries>([]);
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setLoading(true);
        salaryApi.list({companies: [props.template.company.id!]})
            .then(resp => setSalaries(resp.data))
            .then(() => setLoading(false))
    }, [props.template])

    const handleChange = (salary: Salary, checked: boolean) => {
        if (checked){
            api.addSalary(props.template.id, salary.id)
                .then(resp => {
                    props.handleSubmitSuccess(resp.data)
                    let copy = {...props.template};
                    copy.resources.push(resp.data)
                    props.setTemplate({...copy})
                })
        }else{
            api.removeSalary(props.template.id, salary.id)
                .then(resp => {
                    props.handleSubmitSuccess(resp.data)
                    let copy = {...props.template};
                    copy.resources.filter(r => r.id !== resp.data.id)
                    props.setTemplate({...copy})
                })
        }
    }

    return <>
        <div className="offcanvas-header">
            <h5 id="offcanvasTemplateSalariesAddLabel">Ajouter des collaborateurs</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                    aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
            {loading ? <Loader /> : <ul className={'list-group list-group-flush'}>
                {salaries.map(s => <li className={"list-group-item d-flex align-items-center"}>
                    <label htmlFor={`input_${s.id}`} className="flex-grow-1">
                        <h4 className={'mb-0'}>
                            {s.title}
                        </h4>
                        <p className="form-text mb-0">
                            {s.information?.job.title}
                        </p>
                    </label>
                    <div className="flex-grow-0">
                        <div className="form-check form-switch">
                            <input defaultChecked={!!props.template.resources.find(r => r.salary?.id === s.id)} onChange={e => handleChange(s, e.target.checked)} className="form-check-input" type="checkbox" id={`input_${s.id}`} />
                        </div>
                    </div>
                </li>)}
            </ul>}
        </div>
    </>
}

export default TemplateSalaries;