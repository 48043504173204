import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as api from "../../../../adapters/absence";
import * as Yup from "yup";
import {useFormik} from "formik";
import "react-datepicker/dist/react-datepicker.css";
import {showAlertSuccess} from "../../../../actions/alert";
import format from "../../../../utils/locale";
import {Absence, TIME_ACCOUNTED_TYPE} from "../../../../models/absence";
import {ObjectSchema} from "yup";
import Loader from "../../../../utils/loader";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import validationClass from "../../../../utils/validationClass";
import * as type from "../../../../constants/absence";
import FormError from "../../../../utils/formError";
import DatePicker from "react-datepicker";
import DateCustomInput from "../../../../utils/customInput";
import {axiosError} from "../../../../actions/axios";
import {COMPLEMENT_AFTERNOON, COMPLEMENT_ALL_DAY, COMPLEMENT_MORNING} from "../../../../constants/absence";
import {setHours} from "date-fns";
import SmallLoader from "../../../../utils/loader/small";
import {Salary} from "../../../../models/salary";
import * as accessRights from "../../../../constants/accessRight";
import SalarySelector from "../../../salary/selector";
import { typesAvailableToComplementTime } from "../../add/form";

export const AbsenceEditForm: React.FC<{ absence: Absence, handleSubmitSuccess: (abs: any) => any }> = (props) => {

    const {absence, handleSubmitSuccess} = props;

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const [validationSchema, setValidationSchema] = useState<ObjectSchema<any>>(Yup.object().shape({}));
    const [initialValues, setInitialValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(true);
    const [eventPrecisions, setEventPrecisions] = useState<{label: string, value: number}[]>([]);

    useEffect(() => {
        Promise.all([
            initiateFormValues(absence),
            initiateFormValidationSchema(absence)
        ]).then(() => setIsLoading(false));

    },[])

    const initiateFormValidationSchema = (absence: Absence) => {
        let validationShape: any = {
            type: Yup.number().required('Veuillez préciser un type d\'absence'),
            start: Yup.date().required('Champs requis'),
            end: Yup
                .date()
                .required('Veuillez préciser la date de fin')
                .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                    const { start } = this.parent;
                    return value && start <= value
                }),
            status: Yup.number().required('Champs requis'),
            salary: Yup.number().required('Champs requis'),
            timeAccountedType: Yup.number().required('Champs requis'),
        }

        if (absence.timeAccountedType !== 1){
            validationShape.time = Yup.number().required();
        }

        switch (absence.type){
            case 5:
                validationShape.lastDayIn = Yup.date().required('Champs requis');
                break;
            case 6:
                validationShape.eventType = Yup.number().required('Champs requis');
                validationShape.eventPrecision = Yup.number().required('Champs requis');
                break;
            default:
                validationShape.startComplement = Yup.number().required('Champs requis');

                if (format(new Date(absence.start)) !== format(new Date(absence.end)))
                    validationShape.endComplement = Yup.number().required('Champs requis');
        }
        setValidationSchema(Yup.object().shape({...validationShape}))
        return true;
    }

    const initiateFormValues = (absence: Absence) => {

        let _initialValues: any = {
            type: absence.type,
            start: new Date(absence.start),
            end: new Date(absence.end),
            status: absence.status,
            salary: absence.salary?.id,
            timeAccountedType: String(absence.timeAccountedType)
        }

        if (String(absence.timeAccountedType) !== '1'){
            _initialValues.time = absence.time;
        }

        switch (absence.type){
            case type.TYPE_MALADIE:
                _initialValues.lastDayIn = new Date(absence.lastDayIn!)
                break;
            case type.TYPE_EVENT:
                _initialValues.eventType = absence.eventType;
                _initialValues.eventPrecision = absence.eventPrecision;
                setEventPrecisions(type.getEventPrecisionChoices(absence.eventType!))
                break;
            default:
                _initialValues.startComplement = absence.startComplement;
                if (absence.startComplement !== type.COMPLEMENT_ALL_DAY && absence.startComplementTime){
                    _initialValues.startComplementTime = new Date(absence.startComplementTime)
                }

                let startTime, endTime = 0
                startTime = new Date(absence.start).getTime()
                endTime = new Date(absence.end).getTime()

                if (startTime !== endTime){
                    _initialValues.endComplement = absence.endComplement;
                    if (absence.endComplement !== type.COMPLEMENT_ALL_DAY && absence.endComplementTime){
                        _initialValues.endComplementTime = new Date(absence.endComplementTime)
                    }
                }
                break;
        }

        setInitialValues({..._initialValues})
        return true;
    }

    const handleSubmit = (data: Absence) => {
        dispatch(showAlertSuccess("Absence modifiée"));
        formik.setSubmitting(false);
        handleSubmitSuccess(data)
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            api.edit(values, absence.id!).then(data => {
                handleSubmit(data.data)
            }).catch(error => {
                dispatch(axiosError(error))
            })
        }
    })

    if (isLoading) return <Loader />

    const handleTypeChange = (t: number) => {

        let fields: string[] = ['type', 'start', 'end', 'salary', 'status', 'timeAccountedType'];

        const _validationSchema: any = {};
        const _initialValues: any = {};

        switch (t) {
            case type.TYPE_MALADIE:
                fields.push('lastDayIn', 'fileName')
                break;
            case type.TYPE_EVENT:
                fields.push('eventType', 'eventPrecision')
                break;
            default:
                fields.push('startComplement')
                if (formik.values.start.getTime() !== formik.values.end.getTime()){
                    fields.push('endComplement')
                }
        }

        switch (formik.values.timeAccountedType)
        {
            case "2":
            case "3":
                fields.push('time')
                break;
        }

        for(let i in fields){
            switch (fields[i]) {
                case "type":
                    _validationSchema.type = Yup.number().required('Veuillez séléctionner une option')
                    _initialValues.type = t;
                    break;
                case "timeAccountedType":
                    _validationSchema.timeAccountedType = Yup.number().required('Veuillez séléctionner une option')
                    _initialValues.timeAccountedType = formik.values.timeAccountedType;
                    break;
                case "time":
                    _validationSchema.time = Yup.number().required('Veuillez saisir une valeur')
                    _initialValues.time = formik.values.time || 7;
                    break;
                case "start":
                    _validationSchema.start = Yup.date().required('Veuillez préciser une date de début')
                    _initialValues.start = formik.values.start || new Date();
                    break;
                case "end":
                    _validationSchema.end = Yup.date().required('Veuillez préciser une date de fin')
                        .test("is-greater", "La date de fin doit être après le début", function(value: any) {
                            const { start } = this.parent;
                            return value && start <= value
                        });
                    _initialValues.end = formik.values.end || new Date();
                    break;
                case "salary":
                    _validationSchema.salary = Yup.number().required('Veuillez séléctionner un collaborateur')
                    _initialValues.salary = formik.values.salary || null;
                    break;
                case "status":
                    _validationSchema.status = Yup.number().required('Veuillez séléctionner un statut')
                    _initialValues.status = formik.values.status || 1;
                    break;
                case "eventType":
                    _validationSchema.eventType = Yup.number().required("Veuillez séléctionner un type d'évènement")
                    _initialValues.eventType = formik.values.eventType || 1;
                    break;
                case "lastDayIn":
                    _validationSchema.lastDayIn = Yup.date().required("Veuillez préciser une date")
                    _initialValues.lastDayIn = formik.values.lastDayIn || new Date();
                    break;
                case "eventPrecision":
                    _validationSchema.eventPrecision = Yup.number().required("Veuillez préciser le type d'évènement")
                    _initialValues.eventPrecision = formik.values.eventPrecision || 1;
                    break;
                case "startComplement":
                    _validationSchema.startComplement = Yup.number().required("Veuillez séléctionner une option")
                    _initialValues.startComplement = formik.values.startComplement || COMPLEMENT_ALL_DAY;
                    if (_initialValues.startComplement !== COMPLEMENT_ALL_DAY){
                        _validationSchema.startComplementTime = Yup.number().required("Veuillez séléctionner une heure")
                        _initialValues.startComplementTime = formik.values.startComplementTime || formik.values.start
                    }
                    break;
                case "endComplement":
                    _validationSchema.endComplement = Yup.number().required("Veuillez séléctionner une option")
                    _initialValues.endComplement = formik.values.endComplement || COMPLEMENT_ALL_DAY;
                    if (_initialValues.endComplement !== COMPLEMENT_ALL_DAY){
                        _validationSchema.endComplementTime = Yup.number().required("Veuillez séléctionner une heure")
                        _initialValues.endComplementTime = formik.values.endComplementTime || formik.values.end
                    }
                    break;
            }
        }


        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const handleDateChange = (field: "start"|"end", value: Date) => {
        const _initialValues = {...formik.values}
        const _validationSchema = {...validationSchema.fields};

        _initialValues[field] = value;
        let removeFields:string[] = [];

        switch (formik.values.type) {
            case type.TYPE_CP:
            case type.TYPE_RTT:
            case type.TYPE_CSS:
            case type.TYPE_FORM:
            case type.TYPE_REPOS:
            case type.TYPE_CHILD_DISEASE:
            case type.TYPE_WORK_ACCIDENT:
            case type.TYPE_TRAVEL_ACCIDENT:
            case type.TYPE_PROTECTIVE_LAYOFF:
            case type.TYPE_DISCIPLINARY_DISMISSAL:
            case type.TYPE_UNJUSTIFIED:

                _initialValues.startComplement = _initialValues.startComplement || 1
                _validationSchema.startComplement = Yup.number().required();
                if ([COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(_initialValues.startComplement)){
                    _initialValues.startComplementTime = setHours(field === "start" ? value : formik.values.start, formik.values.startComplementTime.getHours());
                }

                if (_initialValues.start.getTime() !== _initialValues.end.getTime()){
                    _initialValues.endComplement = _initialValues.endComplement || 1
                    _validationSchema.endComplement = Yup.number().required();
                    if ([COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(_initialValues.endComplement)){
                        _initialValues.endComplementTime = setHours(field === "end" ? value : formik.values.end, formik.values.endComplementTime.getHours());
                    }
                    break;
                }

                removeFields = ["endComplement", "endComplementTime"]
                for(let f in removeFields){
                    if (_initialValues.hasOwnProperty(removeFields[f])){
                        delete _initialValues[removeFields[f]];
                    }
                    if (_validationSchema.hasOwnProperty(removeFields[f])){
                        delete _validationSchema[removeFields[f]];
                    }
                }
                break;
            default:
                removeFields = ["endComplement", "endComplementTime", "startComplement", "startComplementTime"];
                for(let f in removeFields){
                    if (_initialValues.hasOwnProperty(removeFields[f])){
                        delete _initialValues[removeFields[f]];
                    }
                    if (_validationSchema.hasOwnProperty(removeFields[f])){
                        delete _validationSchema[removeFields[f]];
                    }
                }
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const handleComplementChange = (t: "start"|"end", value: number) => {
        const _initialValues = {...formik.values}
        const _validationSchema = {...validationSchema.fields};

        switch (t) {
            case "start":
                _initialValues['startComplement'] = value;
                switch (value){
                    case 1:
                        delete _initialValues.startComplementTime;
                        delete _validationSchema.startComplementTime;
                        break;
                    default:
                        _initialValues.startComplementTime = setHours(formik.values.start, 12);
                        _validationSchema.startComplementTime = Yup.date().required();
                        break;
                }
                break;
            case "end":
                _initialValues['endComplement'] = value;
                switch (value){
                    case 1:
                        delete _initialValues.endComplementTime;
                        delete _validationSchema.endComplementTime;
                        break;
                    default:
                        _initialValues.endComplementTime = setHours(formik.values.end, 12);
                        _validationSchema.endComplementTime = Yup.date().required();
                        break;
                }
                break;
        }

        setInitialValues({..._initialValues})
        setValidationSchema(Yup.object().shape({..._validationSchema}))
    }

    const filterComplementChoices = (t: "start"|"end") => {
        switch (t) {
            case "start":
                if (formik.values.start.getTime() === formik.values.end.getTime()){
                    return type.getComplementChoices();
                }

                return type.getComplementChoices().filter(c => c.value !== COMPLEMENT_MORNING)
            case "end":
                return type.getComplementChoices().filter(c => c.value !== COMPLEMENT_AFTERNOON)
        }
    }

    const handleTimeAccountedTypeChange = (t: string) => {
        let _initialValues = initialValues;
        let _fields = validationSchema.fields;

        _initialValues.timeAccountedType = t;

        if (t === '1'){
            delete _fields.time
            delete _initialValues.time;
            setValidationSchema(Yup.object().shape({..._fields}))
            setInitialValues({..._initialValues})
        }else{
            _fields.time = Yup.number().required();
            _initialValues.time = _initialValues.time || state.auth.user.currentSalary.companyGroup.absenceSettings.accountedTime || 7;
            setValidationSchema(Yup.object().shape({..._fields}))
            setInitialValues({..._initialValues})
        }
    }

    return (
        <form onSubmit={formik.handleSubmit} className="p-1 p-md-3">
            {/* TYPE */}
            <div className="col-12 mb-3">
                <BootstrapSelect
                    onChange={(choice) => {
                        handleTypeChange(Number(choice!.value))
                    }}
                    className={validationClass(formik.errors, formik.touched, 'type')}
                    value={type.getTypeChoice(formik.values.type) || undefined}
                    label={"Type d'absence"}
                    required={true}
                    options={type.getTypeChoices()} />
                <FormError errors={formik.errors} touched={formik.touched} field={'type'} />
            </div>
            {/* EVENT TYPE */}
            {formik.values.type === type.TYPE_EVENT &&
            <div className="col-12 mb-3">
                <BootstrapSelect
                    onChange={(choice) => {
                        formik.setFieldValue('eventType', choice!.value)
                        formik.setFieldValue('eventPrecision', null)
                        setEventPrecisions([...type.getEventPrecisionChoices(Number(choice!.value))])
                    }}
                    className={validationClass(formik.errors, formik.touched, 'eventType')}
                    label={"Type d'absence"}
                    required={true}
                    value={type.getEventTypeChoices()[formik.values.eventType - 1] || undefined}
                    options={type.getEventTypeChoices()}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'eventType'} />
            </div>
            }
            {/* EVENT PRECISION */}
            {formik.values.type === type.TYPE_EVENT && formik.values.eventType &&
            <div className="col-12 mb-3">
                <BootstrapSelect
                    onChange={(e) => formik.setFieldValue('eventPrecision', e?.value)}
                    className={validationClass(formik.errors, formik.touched, 'eventPrecision')}
                    value={eventPrecisions.find(precision => precision.value === formik.values.eventPrecision)}
                    enableReinitialize={true}
                    label={"Précision"}
                    required={true}
                    options={eventPrecisions}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'eventPrecision'} />
            </div>}
            {/*LAST DAY IN*/}
            {formik.values.type === type.TYPE_MALADIE &&
            <div className="col-12 mb-3">
                <DatePicker
                    onChange={(date) => {
                        formik.setFieldValue('lastDayIn', date)
                    }}
                    dateFormat={"E dd MMM uuuu"}
                    selected={formik.values.lastDayIn}
                    customInput={<DateCustomInput label={"Dernier jour de présence dans l'établissement"} />}
                    endDate={formik.values.start}
                    className={'form-control' + validationClass(formik.errors, formik.touched, 'lastDayIn')}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'lastDayIn'} />
            </div>}
            {/*START*/}
            <div className="col-12 mb-3">
                <div className={'d-flex'}>
                    <div className="col">
                        <DatePicker
                            dateFormat={"E dd MMM uuuu"}
                            selected={formik.values.start}
                            onChange={(date:Date) => {
                                handleDateChange('start', date);
                            }}
                            customInput={<DateCustomInput  label={'Date de début'} />}
                            selectsStart
                            startDate={formik.values.start}
                            endDate={formik.values.end}
                            title={'Date de début'}
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'start')}
                        />
                    </div>
                    {typesAvailableToComplementTime.includes(formik.values.type) &&
                    <div className={'col'}>
                        <BootstrapSelect
                            label={"Précision"}
                            options={filterComplementChoices('start')}
                            value={type.getComplementChoices().find(c => c.value === formik.values.startComplement) || undefined}
                            required={true}
                            onChange={(choice) => handleComplementChange("start", Number(choice!.value))}
                        />
                    </div>
                    }
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'start'} />
                <FormError errors={formik.errors} touched={formik.touched} field={'startComplement'} />
            </div>
            {[COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(formik.values.startComplement) && <div className={'col-12 mb-3'}>
                <DatePicker
                    onChange={(date) => {
                        formik.setFieldValue('startComplementTime', date)
                    }}
                    dateFormat={"HH:mm"}
                    showTimeSelectOnly={true}
                    showTimeSelect={true}
                    selected={formik.values.startComplementTime}
                    customInput={<DateCustomInput label={formik.values.startComplement === COMPLEMENT_MORNING ? "Absent jusqu'à" : "Absent à partir de"} />}
                    endDate={formik.values.end}
                    className={'form-control' + validationClass(formik.errors, formik.touched, 'startComplementTime')}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'startComplementTime'} />
            </div>}
            {/*END*/}
            <div className="col-12 mb-3">
                <div className="d-flex">
                    <div className="col">
                        <DatePicker
                            selected={formik.values.end}
                            dateFormat={"E dd MMM uuuu"}
                            onChange={(date:Date, event) => {
                                handleDateChange('end', date);
                            }}
                            customInput={<DateCustomInput label={'Date de fin'} />}
                            selectsEnd
                            startDate={formik.values.start}
                            endDate={formik.values.end}
                            minDate={formik.values.start}
                            title={'Date de fin'}
                            className={'form-control' + validationClass(formik.errors, formik.touched, 'end')}
                        />
                    </div>
                    <div className="clearfix"> </div>
                    {typesAvailableToComplementTime.includes(formik.values.type) && formik.values.start.getTime() !== formik.values.end.getTime() &&
                    <>
                        <div className="col">
                            <BootstrapSelect
                                label={"Précision"}
                                options={filterComplementChoices('end')}
                                value={type.getComplementChoices().find(c => c.value === formik.values.endComplement) || undefined}
                                required={true}
                                onChange={(choice) => handleComplementChange("end", Number(choice!.value))}
                            />
                        </div>
                    </>}
                </div>
                <FormError errors={formik.errors} touched={formik.touched} field={'end'} />
                <FormError errors={formik.errors} touched={formik.touched} field={'endComplement'} />
            </div>
            {[COMPLEMENT_MORNING, COMPLEMENT_AFTERNOON].includes(formik.values.endComplement) && <div className={'col-12 mb-3'}>
                <DatePicker
                    onChange={(date) => {
                        formik.setFieldValue('endComplementTime', date)
                    }}
                    dateFormat={"HH:mm"}
                    showTimeSelectOnly={true}
                    showTimeSelect={true}
                    selected={formik.values.endComplementTime}
                    customInput={<DateCustomInput label={"Absent jusqu'à"} />}
                    endDate={formik.values.end}
                    className={'form-control' + validationClass(formik.errors, formik.touched, 'endComplementTime')}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'endComplementTime'} />
            </div>}
            {/*SALARY*/}
            {<div className="col-12 mb-3">
                <SalarySelector
                    fetchOptions={true}
                    value={absence.salary}
                    required={true}
                    onChange={(choice: Salary) => formik.setFieldValue('salary', choice.id)}
                    accessRight={accessRights.EDIT_ABSENCE}
                />
                <FormError errors={formik.errors} touched={formik.touched} field={'salary'}/>
            </div>}
            {<>
                <div className="col-12 mb-3">
                    <BootstrapSelect
                        key={absence.id}
                        enableReinitialize={true}
                        options={TIME_ACCOUNTED_TYPE}
                        value={TIME_ACCOUNTED_TYPE.find(t => t.value === String(absence.timeAccountedType))}
                        label={'Comptabilisation des heures'}
                        required={true}
                        onChange={(c) => handleTimeAccountedTypeChange(String(c!.value))}
                    />
                </div>
                {formik.values.timeAccountedType !== '1' && <div className="col-12 mb-3">
                    <div className="form-floating">
                        <input key={absence.id} type="number" name={'time'} defaultValue={formik.values.time} step={'any'} onChange={formik.handleChange} className={'form-control'} />
                        <label>
                            Nombre d'heures
                        </label>
                    </div>
                </div>}
            </>}
            {/*STATUS*/}
            <div className="col-12 mb-3">
                <div className="col-12">
                    <BootstrapSelect
                        label={'Statut'}
                        options={type.getStatusChoices()}
                        value={type.getStatusChoices()[formik.values.status - 1] || undefined}
                        onChange={(choice) => formik.setFieldValue('status', choice!.value)}
                        className={validationClass(formik.errors, formik.touched, 'status')}
                        required={true}
                    />
                    <FormError errors={formik.errors} touched={formik.touched} field={'status'}/>
                </div>
            </div>
            <button type={'submit'} className='btn btn-light w-100' disabled={formik.isSubmitting}>
                {formik.isSubmitting ? <SmallLoader /> : <><i className={'bi bi-check-lg'}> </i> Enregistrer</>}
            </button>
        </form>
    )
}

export default AbsenceEditForm;