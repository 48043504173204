import React, {useEffect, useState} from "react";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import * as actions from "../../../../actions/salary";
import * as jobActions from "../../../../actions/job";
import * as salaryApi from "../../../../adapters/salary";
import * as jobApi from "../../../../adapters/job";
import * as types from "../../../../constants/salary";
import Loader from "../../../../utils/loader";
import * as Yup from "yup";
import {useFormik} from "formik";
import {closeSidebar, submitSidebar} from "../../../../actions/rightSidebar";
import {showAlertDanger, showAlertSuccess} from "../../../../actions/alert";
import {Salary} from "../../../../models/salary";
import DatePicker from "react-datepicker";
import BootstrapSelect from "../../../../utils/bootstrapSelect";
import FormError from "../../../../utils/formError";
import CustomInput from "../../../../utils/customInput";
import validationClass from "../../../../utils/validationClass";

const initialSchema = Yup.object().shape({
    job: Yup.string().required('Veuillez préciser un métier'),
    contract: Yup.number().required('Veuillez préciser un contract'),
    startDate: Yup.string().required('Veuillez préciser une date de début de contract'),
    endDate: Yup.string().nullable().optional(),
    flatRate: Yup.number().required('Veuillez choisir un type de facturation'),
    contractWorkingTime: Yup.number().max(44).optional(),
    dayAnnualFlatRate: Yup.number().nullable().optional(),
    hourlyRate: Yup.number().nullable().optional(),
    socialSecurityNumber: Yup.number().nullable().optional(),
    nightWorker: Yup.boolean(),
    absenceBasis: Yup.number().nullable().optional(),
    absenceBasisN1: Yup.number().nullable().optional(),
    overtimeBasis: Yup.number().nullable().optional(),
});

export const SalaryInformationAdd: React.FC = () => {

    const dispatch = useDispatch();
    const state = useSelector((state: RootStateOrAny) => state)
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        jobApi.list().then(data => {
            dispatch(jobActions.fetchJobsSuccess(data.data))
            setIsLoading(false)
        })
    }, [])

    let date = new Date()
    date.setHours(0, 0, 0, 0);

    const initialValues = {
        job: state.job.payload[0]?.id,
        contract: 1,
        startDate: date,
        endDate: null,
        flatRate: types.getFlatRateChoices()[0].value,
        contractWorkingTime: 35,
        dayAnnualFlatRate: null,
        hourlyRate: null,
        socialSecurityNumber: '',
        nightWorker: false,
        absenceBasis: null,
        absenceBasisN1: null,
        overtimeBasis: null,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: initialSchema,
        onSubmit: values => {
            dispatch(submitSidebar());
            let salary: Salary = state.rightSidebar.data.salary;
            salary.information = values;
            salaryApi.create({ ...salary, tel: `+${salary.tel}` }).then(data => {
                dispatch(actions.addSalarySuccess(data.data))
                dispatch(showAlertSuccess(data.data.title + ' a bien été ajouté !'))
                dispatch(closeSidebar());
            }).catch(error => {
                dispatch(showAlertDanger(error?.response?.data?.message))
            })
        }
    })


    if (isLoading || state.job.isLoading || state.salary.isLoading)
        return (<Loader />);

    return (
        <div className={'p-3'}>
            <div className="position-relative m-4">
                <div className="progress" style={{height: 1}}>
                    <div className="progress-bar" role="progressbar" style={{width: '100%'}} aria-valuenow={100}
                         aria-valuemin={0} aria-valuemax={100}> </div>
                </div>
                <button type="button"
                        className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-success rounded-pill"
                        style={{width: 32, height:32}}>1
                </button>
                <button type="button"
                        className="position-absolute top-0 start-100 translate-middle btn btn-sm btn-primary rounded-pill"
                        style={{width: 32, height:32}}>2
                </button>
            </div>
                    <form onSubmit={formik.handleSubmit} id={"form"} className={'h-100'} >
                        <div className="flex-grow-1 mb-3">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <BootstrapSelect
                                        onTouch={(touched) => formik.setFieldTouched('job', touched)}
                                        onChange={(choice) => {
                                            formik.setFieldValue('job', choice?.value)
                                        }}
                                        fetchEntity={'job'}
                                        label={'Métier'}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'job'} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <BootstrapSelect
                                        onTouch={(touched) => formik.setFieldTouched('contract', touched)}
                                        label={'Contrat'}
                                        onChange={(choice) => {
                                            formik.setFieldValue('contract', choice?.value)
                                        }}
                                        name={'contract'}
                                        options={types.getContractChoices()}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'contract'} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <DatePicker
                                        customInput={<CustomInput label={'Début de contrat *'} />}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue('startDate', date)
                                        }}
                                        dateFormat={"E dd MMM uuuu"}
                                        className={'form-control' + validationClass(formik.errors, formik.touched, 'startDate')}
                                        selected={formik.values.startDate}
                                        endDate={formik.values.endDate}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'startDate'} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <DatePicker
                                        customInput={<CustomInput label={'Fin de contrat'} />}
                                        onChange={(date: Date) => {
                                            formik.setFieldValue('endDate', date)
                                        }}
                                        className={'form-control' + validationClass(formik.errors, formik.touched, 'endDate')}
                                        dateFormat={"E dd MMM uuuu"}
                                        selected={formik.values.endDate}
                                        startDate={formik.values.startDate}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'endDate'} />
                                </div>
                                <div className="col-12 mb-3">
                                    <BootstrapSelect
                                        onTouch={(touched) => formik.setFieldTouched('flatRate', touched)}
                                        onChange={(choice) => {
                                            formik.setFieldValue('flatRate', choice?.value)
                                        }}
                                        label={'Type de facturation'}
                                        required={true}
                                        options={types.getFlatRateChoices()}
                                    />
                                    <FormError errors={formik.errors} touched={formik.touched} field={'flatRate'} />
                                </div>
                                {types.FLAT_RATE_HC === formik.values.flatRate &&
                                    <div className="col-12 mb-3">
                                        <div className="form-floating">
                                            <input onBlur={formik.handleBlur} onChange={formik.handleChange} type={'number'} step={'any'} className='form-control' name={`contractWorkingTime`} />
                                            <label htmlFor="contractHours">
                                                Heures contractuelles
                                            </label>
                                            <FormError errors={formik.errors} touched={formik.touched} field={'contractWorkingTime'} />
                                        </div>
                                    </div>}
                                {types.FLAT_RATE_DAILY === formik.values.flatRate &&
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating">
                                            <input onBlur={formik.handleBlur} onChange={formik.handleChange} type={'number'} className='form-control' name={`dayAnnualFlatRate`} />
                                            <label htmlFor="dayAnnualFlatRate">
                                                Nombre de jours par an
                                            </label>
                                            <FormError errors={formik.errors} touched={formik.touched} field={'dayAnnualFlatRate'} />
                                        </div>
                                    </div>}
                                {(types.FLAT_RATE_HC == formik.values.flatRate || types.FLAT_RATE_ELSE == formik.values.flatRate) &&
                                    <div className="col-md-6 mb-3">
                                        <div className="form-floating">
                                            <input onChange={formik.handleChange} onBlur={formik.handleBlur} type={'number'} step={'any'} className='form-control' name={`hourlyRate`} />
                                            <label htmlFor="hourlyRate">
                                                Taux horaire brut
                                            </label>
                                        </div>
                                        <FormError errors={formik.errors} touched={formik.touched} field={'hourlyRate'} />
                                    </div>}
                                <div className="col-md-6 mb-3">
                                    <div className="form-floating">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type={'text'} className='form-control' name={`socialSecurityNumber`} />
                                        <label htmlFor="socialSecurityNumber">
                                            N° de sécurité sociale
                                        </label>
                                    </div>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'socialSecurityNumber'} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-check form-switch">
                                        <input onChange={formik.handleChange} type={'checkbox'} className={'form-check-input'} defaultChecked={false} name={`nightWorker`} />
                                        <label className="form-check-label" htmlFor={`nightWorker`}>
                                            Travailleur de nuit
                                        </label>
                                        <FormError errors={formik.errors} touched={formik.touched} field={'nightWorker'} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-floating">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type={'number'} step={'any'} className='form-control' name={`absenceBasis`} />
                                        <label htmlFor="absenceBasis">
                                            Congés pris (N)
                                        </label>
                                    </div>
                                    <FormError errors={formik.errors} touched={formik.touched} field={'absenceBasis'} />
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-floating">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type={'number'} step={'any'} className='form-control' name={`absenceBasisN1`} />
                                        <label htmlFor="absenceBasisN1">
                                            Congés pris (N-1)
                                        </label>
                                        <FormError errors={formik.errors} touched={formik.touched} field={'absenceBasisN1'} />
                                    </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                    <div className="form-floating">
                                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type={'number'} step={'any'} className='form-control' name={`overtimeBasis`} />
                                        <label htmlFor="overtimeBasis">
                                            Base d'heures supplémentaires
                                        </label>
                                        <FormError errors={formik.errors} touched={formik.touched} field={'overtimeBasis'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-grow-0">
                            <button className='btn btn-outline-primary w-100' type="submit">
                                <i className={'bi bi-check'}> </i> Valider
                            </button>
                        </div>
                    </form>
        </div>
    )
}

export default SalaryInformationAdd;