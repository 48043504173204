import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik';
import * as Yup from 'yup';

import DatePicker from "react-datepicker";
import SellsyContactListModal from "./sellsy";

import { register } from "../../../adapters/auth";
import { setPageTitle } from "../../../actions/header";

import BootstrapSelect from "../../../utils/bootstrapSelect";
import validationClass from "../../../utils/validationClass";
import FormError from "../../../utils/formError";
import DateCustomInput from "../../../utils/customInput";

const RegisterForm = () => {
    const dispatch = useDispatch();

    const state = useSelector((state: RootStateOrAny)  => state);

    const [error, setError] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [title, setTitle] = useState<string>()
    const [isRegistered, setIsRegistered] = useState(false)
    const [contact, setContact] = useState<{
        id: number,
        email: string,
        first_name: string,
        last_name: string
    }>()

    const [initialValues, setInitialValues] = useState<any>({
        firstname: '',
        lastname: '',
        companyGroup: '',
        billingVersion: 2,
        billingRate: 1,
        sellsyId: null,
        type: 1,
        birthdate: new Date(),
        tel: '',
        email: '',
        password: '',
        repeatPassword: '',
    })

    const [validationSchema, setValidationSchema] = useState<any>(Yup.object().shape({
        firstname: Yup.string().required('Prénom obligatoire'),
        lastname: Yup.string().required('Nom obligatoire'),
        companyGroup: Yup.string().required('Nom obligatoire'),
        billingVersion: Yup.number().required('Type de facturation requis.'),
        billingRate: Yup.number().required('Taux de facturation requis.'),
        sellsyId: Yup.number().required('Contact sellsy requis.'),
        type: Yup.number().required('Champs requis !'),
        birthdate: Yup.date().required('Champs requis !'),
        tel: Yup.string().required('Champs requis !'),
        email: Yup.string().email('Email non valide').required('Email obligatoire'),
        password: Yup.string().required('Mot de passe obligatoire'),
        repeatPassword: Yup.string().required('Confirmez le mot de passe'),
    }))

    function handleBillingVersionChange(version: number){
        let _values = {...formik.values}
        let _schema = {...validationSchema.fields}

        _values.billingVersion = version;

        if (version === 1){
            delete _values.sellsyId;
            delete _schema.sellsyId
        }else if(version === 2){
            _values.sellsyId = null;
            _schema.sellsyId = Yup.number().required('Contact sellsy requis.');
        }

        setInitialValues(_values)
        setValidationSchema(Yup.object().shape(_schema))
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values, formikHelpers) => {
            register(values)
                .then(data => {
                setIsRegistered(true)
                setEmail(data.data.email)
                setTitle(data.data.title)
            })
                .catch(error => {
                    setError(error.response.data.message)
                    formikHelpers.setSubmitting(false);
                })
        }
    })

    useEffect(() => {
        if (contact){
            formik.setFieldValue("sellsyId", contact.id)
        }
    }, [contact])

    useEffect(() => {
        dispatch(setPageTitle('Ajouter un utilisateur'))
    }, [])

    if (isRegistered) {
        return  (
            <div className="container mx-auto h-100 my-1 my-md-4">
                <div className={"card shadow-sm p-1 p-md-3"}>
                    <div className="card-body text-center">
                        <span className={'fs-3 mb-3'}>
                            <i className={'bi bi-check2-circle text-success'}> </i> Félicitation.
                            <span className="clearfix"> </span>
                            Le compte Sivan de {title} à bien été créé
                        </span>
                        <p className="text-light-s">
                            Un email a été envoyé à l'adresse email : {email}
                            <span className="clearfix"> </span>
                            Veuillez suivre les instructions préciser dans ce mail pour activer le compte.
                        </p>
                        <button className={'btn btn-outline-primary w-100'} onClick={() => setIsRegistered(false)}>
                            <i className={'bi bi-arrow-repeat'}> </i> Ajouter un autre compte
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="container mx-auto h-100 my-1 my-md-4">
            <form className={"card shadow-sm position-relative"} onSubmit={formik.handleSubmit}>
                <div className="card-body">
                    {error &&
                        <div className={'alert alert-danger p-2 mb-2'}>
                            <i className={'bi bi-exclamation-triangle'}> </i> {error}
                        </div>
                    }

                    <div className="row mb-2">
                        <div className="col-md-6">
                            <BootstrapSelect
                                required={true}
                                label={"Type de facturation"}
                                value={{label: "Automatique", value: 2}}
                                options={[
                                    {label: "Automatique", value: 2},
                                    {label: "Manuelle", value: 1},
                                ]}
                                onChange={(c) => handleBillingVersionChange(Number(c!.value))}
                            />
                        </div>
                        <div className="col-md-6">
                            <BootstrapSelect
                                className={validationClass(formik.errors, formik.touched, 'type') + " mb-3"}
                                required={true}
                                label={"Taux de facturation"}
                                value={{ label: "Taux normal", value: 1 }}
                                options={[
                                    { label: "Taux normal", value: 1 },
                                    { label: "Taux réduit", value: 2 },
                                ]}
                                onChange={(choice) => formik.setFieldValue("billingRate", choice?.value)}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={'form-control'}
                                    name="firstname"
                                    type="text"
                                    disabled={formik.isSubmitting}
                                />
                                <label htmlFor="firstname">
                                    Prénom
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'firstname'} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} name="lastname" type="text" disabled={formik.isSubmitting}/>
                                <label htmlFor="lastname">
                                    Nom
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'lastname'} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <DatePicker
                                customInput={<DateCustomInput label={'Date de naissance'} />}
                                className={"form-control mb-3"}
                                onChange={(date: Date) => {
                                    formik.setFieldValue('birthdate', date)
                                }}
                                dateFormat={"E dd MMM uuuu"}
                                selected={formik.values.birthdate}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                endDate={new Date()}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} name="tel" type="tel" disabled={formik.isSubmitting}/>
                                <label htmlFor="tel">
                                    Tél
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'tel'} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} name="email" type="email" disabled={formik.isSubmitting}/>
                                <label htmlFor="email">
                                    Email
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'email'} />
                            </div>
                        </div>
                    </div>

                    {formik.values.billingVersion === 2 &&
                        <div className="col-12 mb-3">
                            {contact && <p className={"mb-1"}></p>}
                            <button type={"button"} className={'btn btn-sm btn-light'} data-bs-toggle="modal"
                                    data-bs-target="#SellsyContactListModal">
                                {contact ? <>{contact.first_name} {contact.last_name}, {contact.email}</> : <>Séléctionnez un contact dans sellsy</>}
                            </button>
                        </div>
                    }

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} name="companyGroup" type="text" disabled={formik.isSubmitting}/>
                                <label htmlFor="companyGroup">
                                    Nom de l'entreprise
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'companyGroup'} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <BootstrapSelect
                                value={{label: "Pharmacie", value: 1}}
                                options={[
                                    {label: "Pharmacie", value: 1},
                                    {label: "Centre dentaire", value: 2},
                                    {label: "Centre médical", value: 3},
                                    {label: "Laboratoire", value: 4},
                                    {label: "Radiologie", value: 12},
                                    {label: "Hôtel", value: 5},
                                    {label: "Restaurant", value: 6},
                                    {label: "Fitness", value: 7},
                                    {label: "BTP", value: 8},
                                    {label: "Boulangerie", value: 9},
                                    {label: "Papéterie", value: 10},
                                    {label: "Vétérinaire", value: 11},
                                    {label: "Autre", value: 0},
                                ]}
                                className={validationClass(formik.errors, formik.touched, 'type') + " mb-3"}
                                label={"Type d'entreprise"}
                                required={true}
                                onChange={(choice) => formik.setFieldValue("type", choice?.value)}
                            />
                            <FormError errors={formik.errors} touched={formik.touched} field={'type'} />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input onChange={formik.handleChange} onBlur={formik.handleBlur} className={'form-control'} name="password" type="password" disabled={formik.isSubmitting}/>
                                <label htmlFor="password">
                                    Mot de passe
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'password'} />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-floating mb-3">
                                <input onChange={formik.handleChange}  onBlur={formik.handleBlur} className={'form-control'} name="repeatPassword" type="password" disabled={formik.isSubmitting}/>
                                <label htmlFor="repeatPassword">
                                    Répétez le mot de passe
                                </label>
                                <FormError errors={formik.errors} touched={formik.touched} field={'repeatPassword'} />
                            </div>
                        </div>
                    </div>

                    <button className={'btn btn-outline-success w-100'} type="submit" disabled={formik.isSubmitting}>
                        <i className={'bi bi-check-circle'}> </i> {state.auth.loading ? "Enregistrement en cours" : "Valider"}
                    </button>
                </div>
            </form>

            <SellsyContactListModal setContact={c => setContact(c)} email={formik.values.email} />
        </div>
    )
}

export default RegisterForm;