import React, { useState } from 'react'

import CompanySubscriptionsItem from "./CompanySubscriptionsItem"

import { Company } from '../../../../../../models/companies'
import { ISellsyContactCompany } from '../../../../../../@types/models/Sellsy/ISellsyContactCompany'

interface IProps {
  company: Company;
  sellsyCompanies: ISellsyContactCompany[];
  companyGroupBillingRate: number|null;
  subscriptionIsLoading: boolean;
  onLink: (sellsyId: number) => void;
  onCreateSubscription: (data: { subscriptionModelId: number, subscriptionStartDate: Date, billingStartDate: Date }) => void;
  onUpdateSubscription: (data: { subscriptionModelId: number, subscriptionStartDate: Date, billingStartDate: Date }) => void;
}

const CompanyLinkItem: React.FC<IProps> = ({ company, sellsyCompanies, companyGroupBillingRate, subscriptionIsLoading, onLink, onCreateSubscription, onUpdateSubscription }) => {  
  const [selectedSellsyCompanyId, setSelectedSellsyCompanyId] = useState<number|undefined>(undefined)
  const [showSubscriptions, setShowSubscriptions] = useState<boolean>(false)
  
  return (
    <div className="my-1 p-2 border rounded">
      <div key={company.id} className="container g-0 row align-items-center">
        <div className="col-5">
          <span className={`mb-1 badge ${company?.sellsyId ? 'bg-success' : 'bg-danger'}`}>
              {company?.sellsyId ? 'Liée à Sellsy' : 'Non liée à Sellsy'}
          </span>
          <p className="m-0">
            {company?.title}
          </p>
          <div className="d-flex">
            <p className="m-0 text-primary" style={{ cursor: 'pointer' }} onClick={() => setShowSubscriptions(!showSubscriptions)}>
              Gérer les abonnements
            </p>
          </div>
        </div>

        <div className="col-4 px-1">
          <select
            className="form-select"
            value={selectedSellsyCompanyId}
            onChange={(e: any) => {            
              setSelectedSellsyCompanyId(e.target.value === "0" ? undefined : parseInt(e.target.value))
            }}
          >
            <option selected={!company?.sellsyId} value={0}>Entreprises sur Sellsy</option>
            {sellsyCompanies.map(sellsyCompany => (
              <option selected={company?.sellsyId === sellsyCompany.id} value={sellsyCompany.id}>
                {`${sellsyCompany.name} (${sellsyCompany?.legal_france?.siret || 'SIRET inconnu'})`}
              </option>
            ))}
          </select>
        </div>

        <div className="col-3 d-flex justify-content-end">
          <button
            className="btn btn-primary text-white"
            disabled={!selectedSellsyCompanyId}
            onClick={() => {
              if (selectedSellsyCompanyId) {
                onLink(selectedSellsyCompanyId)
              }
            }}
          >
            Lier à Sellsy
          </button>
        </div>
      </div>

      {showSubscriptions &&
        <CompanySubscriptionsItem
          subscriptions={company?.subscriptions || []}
          companyGroupBillingRate={companyGroupBillingRate}
          subscriptionIsLoading={subscriptionIsLoading}
          onCreateSubscription={onCreateSubscription}
          onUpdateSubscription={onUpdateSubscription}
        />
      }
    </div>
  )
}

export default CompanyLinkItem