import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import TimeClockConfig from "../timeClock";

import { AccountingSettings } from "../../../../models/companyGroup";

import { getAvailableFields } from "../../../../constants/reporting";

import * as API from "../../../../adapters/companyGroup";
import { editUserCompanyGroupSuccess } from "../../../../actions/auth";

import HasModule from "../../../../security/hasModule";

const AccountingConfig:React.FC = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    
    const state = useSelector((state:RootStateOrAny) => state)
    const settings: AccountingSettings = state.auth.user.currentSalary.companyGroup.accountingSettings;
    
    const [labourDaysWorked, setLabourDaysWorked] = useState<number[]>(settings.labourDaysWorked || []);
    const [regionalLabourDays, setRegionalLabourDays] = useState<number[]>(settings.regionalLabourDays || []);
    const [fields, setFields] = useState<string[]>(settings.fields || []);
    const [absenceTypes, setAbsenceTypes] = useState<string[]>(settings.accountedAbsenceType || []);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleChange = (setting: string, value: any) => {
        setIsLoading(true);
        API.accountingSetting({
            setting: setting,
            value: value
        }).then((data) => {
            dispatch(editUserCompanyGroupSuccess(data.data))
            setIsLoading(false);
        })
    }

    function handleLabourDayWorkedChange(labourDay: number, worked: boolean)
    {
        if (worked && !labourDaysWorked.includes(labourDay)){
            setLabourDaysWorked(prev => [...prev, labourDay]);
        }else{
            setLabourDaysWorked(prev => [...prev.filter(l => l != labourDay)]);
        }
    }

    function handleRegionalLabourDayChange(labourDay: number, checked: boolean)
    {
        if (checked && !regionalLabourDays.includes(labourDay)){
            setRegionalLabourDays(prev => [...prev, labourDay]);
        }else{
            setRegionalLabourDays(prev => [...prev.filter(l => l != labourDay)]);
        }
    }

    useEffect(() => {
        handleChange('labour_days_worked', labourDaysWorked)
    }, [labourDaysWorked])

    useEffect(() => {
        handleChange('regional_labour_days', regionalLabourDays)
    }, [regionalLabourDays])

    useEffect(() => {
        handleChange('fields', fields)
    }, [fields])

    useEffect(() => {
        handleChange('accounted_absence_types', absenceTypes)
    }, [absenceTypes])

    const absence_types = [
        {
            camelCase: "paidVacation",
            name: "Congés payés"
        },
        {
            camelCase: "rtt",
            name: "RTT"
        },
        {
            camelCase: "unpaidVacation",
            name: "Congés sans solde"
        },
        {
            camelCase: "recovery",
            name: "Repos"
        },
        {
            camelCase: "sickLeave",
            name: "Arrêt maladie"
        },
        {
            camelCase: "specialEvent",
            name: "Évènement exceptionnel"
        },
        {
            camelCase: "technicalUnemployment",
            name: "Chômage technique"
        },
        {
            camelCase: "formation",
            name: "Formation"
        },
        {
            camelCase: "maternityLeave",
            name: "Congé maternité"
        },
        {
            camelCase: "paternityLeave",
            name: "Congé paternité"
        },
        {
            camelCase: "parentalLeave",
            name: "Congé parental"
        },
        {
            camelCase: "childDisease",
            name: "Enfant malade"
        },
        {
            camelCase: "workAccident",
            name: "Accident de travail"
        },
        {
            camelCase: "travelAccident",
            name: "Accident de trajet"
        },
        {
            camelCase: "protectiveLayoff",
            name: "Mise à pied conservatoire"
        },
        {
            camelCase: "disciplinaryDismissal",
            name: "Mise à pied disciplinaire"
        },
        {
            camelCase: "unjustified",
            name: "Absence non justifiée"
        }
    ];

    const labour_days = [
        { title: "Jour de l’an", id: 1 },
        { title: "Fête du travail", id: 2 },
        { title: "Victoire 1945", id: 3 },
        { title: "Fete nationale", id: 4 },
        { title: "Assomption", id: 5 },
        { title: "Toussaint", id: 6 },
        { title: "Armistice 1918", id: 7 },
        { title: "Noël", id: 8 },
        { title: "Lundi de Pâques", id: 9 },
        { title: "Ascension", id: 10 },
        { title: "Pentecote", id: 11 }
    ];

    const regional_labour_days = [
        {title: "Saint Étienne", id: 12},
        {title: "Vendredi saint", id: 13},
        {title: "Journée commémorative du souvenir de l'esclavage et de son abolition", id: 14},
        {title: "Journée commémorative du souvenir de l'esclavage et de son abolition (La Réunion)", id: 15},
        {title: "Abolition de l'esclavage et la Guerre de Guadeloupe de 1802", id: 16},
        {title: "La Sainte Dévote", id: 17},
        {title: "La Fête Dieu", id: 18},
        {title: "La Fête du Prince (Fête Nationale Monégasque)", id: 19}
    ];

    return (
        <>
            <div className="col-12 mb-2">
                <div className="p-3">
                    <div className="card p-3">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <label htmlFor="">
                                        Champs à afficher / exporter dans les rapports
                                    </label>
                                    <div className="form-text">
                                        Séléctionnez les champs que vous souhaitez afficher de manière permanente
                                    </div>
                                </div>
                                <div className="col-12">
                                    {getAvailableFields().map((item) =>
                                        <>
                                            <h4 className="mt-4 mb-2">
                                                {item.title}
                                            </h4>

                                            <ul className={'list-group list-group-flush'}>
                                                {item.fields.map(field =>
                                                    <li className={'list-group-item'}>
                                                        <div className="form-check form-switch">
                                                            <input
                                                                id={`fields_${field.camelCase}`}
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                defaultChecked={fields.includes(field.camelCase)}
                                                                name={'fields[]'} disabled={isLoading} onChange={(e) => {
                                                                    if (e.target.checked && !fields.includes(e.target.value)) {
                                                                        setFields([...fields, e.target.value])
                                                                    } else if (!e.target.checked && fields.includes(e.target.value)) {
                                                                        setFields(
                                                                            [...fields.filter(a => a !== e.target.value)]
                                                                        )
                                                                    }
                                                                }}
                                                                value={field.camelCase}
                                                            />

                                                            <label
                                                                className="form-check-label"
                                                                htmlFor={`fields_${field.camelCase}`}
                                                            >
                                                                {field.name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>
                            <hr/>
                        </div>
                        
                        {pathname.includes('/settings') &&
                            <div>
                                <div className="col-12">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="">
                                            Motifs d'absences à ajouter au volume d'heure
                                        </label>
                                        <div className="form-text">
                                            Séléctionnez les absences que vous souhaitez intégrer aux volume d'heure
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        {absence_types.map((item) => {
                                            return  <div className="form-check form-switch">
                                                <input defaultChecked={absenceTypes.includes(item.camelCase)} name={'accounted_absence_types[]'} disabled={isLoading} onChange={(e) => {
                                                    if (e.target.checked){
                                                        setAbsenceTypes([...absenceTypes, e.target.value])
                                                    }else if (!e.target.checked){
                                                        setAbsenceTypes(
                                                            [...absenceTypes.filter(a => a !== e.target.value)]
                                                        )
                                                    }
                                                }}  value={item.camelCase} className="form-check-input" type="checkbox"
                                                    id={`accounted_absence_types_${item.camelCase}`} />
                                                <label className="form-check-label" htmlFor={`accounted_absence_types_${item.camelCase}`}>
                                                    {item.name}
                                                </label>
                                            </div>
                                        })}
                                    </div>
                                </div>

                                <hr/>

                                <div className="col-12 d-flex">
                                    <div className="col mb-3">
                                        <label htmlFor="">
                                            Comptabiliser le nombre d'heure contrat en cas d'absence ajoutée au volume d'heure sur une semaine complète
                                        </label>
                                        <div className="form-text">
                                            Si l'absence est sur une semaine complète, comptabilisez automatiquement le nombre d'heure contrat.
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-check form-switch">
                                            <input onChange={(e) => handleChange('absence_disabled_counter', e.target.checked)}  defaultChecked={settings.absenceDisabledCounter} disabled={isLoading} className="form-check-input" type="checkbox" />
                                        </div>
                                    </div>
                                </div>

                                <hr/>

                                <div className="col-12 d-flex">
                                    <div className="col mb-3">
                                        <label htmlFor="">
                                            Majorer les jours fériés
                                        </label>
                                        <div className="form-text">
                                            Cocher pour majorer à 100% les jours fériés travaillés dans les compteurs
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="form-check form-switch">
                                            <input onChange={(e) => handleChange('absence_disabled_counter', e.target.checked)}  defaultChecked={settings.absenceDisabledCounter} disabled={isLoading} className="form-check-input" type="checkbox" />
                                        </div>
                                    </div>
                                </div>

                                <hr/>

                                <div className="col-12 d-flex flex-column">
                                    <div className="col mb-3">
                                        <label htmlFor="">
                                            Jours fériés travaillés normalement
                                        </label>
                                        <ul className={"list-unstyled"}>
                                            <li className="form-text">
                                                En cas de jour férié normalement travaillé, si un congé est posé, il sera décompté.
                                            </li>
                                            <li className="form-text">
                                                En cas de jour férié travaillé sur base de volontariat, si un congé est posé, il ne sera pas décompté.
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className={'list-group list-group-flush'}>
                                        {[...labour_days, ...regional_labour_days.filter(d => regionalLabourDays.includes(d.id))].map(l => <li className={'list-group-item'}>
                                            <div className="w-100 d-flex">
                                                <label className="flex-grow-1" htmlFor={`labourDay${l.id}`}>
                                                    {l.title}
                                                </label>
                                                <div className="flex-grow-0">
                                                    <div className="form-check form-switch">
                                                        <input id={`labourDay${l.id}`} onChange={(e) => handleLabourDayWorkedChange(l.id, e.currentTarget.checked)}  defaultChecked={labourDaysWorked.includes(l.id)} disabled={isLoading} className="form-check-input" type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>

                                <hr/>

                                <div className="col-12 flex-column">
                                    <div className="col mb-3">
                                        <label htmlFor="">
                                            Jours fériés régionaux
                                        </label>
                                        <ul className={"list-unstyled"}>
                                            <li className="form-text">
                                                Veuillez cocher les jours fériés exceptionnel s'appliquant sur votre région
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className={'list-group list-group-flush'}>
                                        {regional_labour_days.map(l => <li className={'list-group-item'}>
                                            <div className="w-100 d-flex">
                                                <label className="flex-grow-1" htmlFor={`labourDay${l.id}`}>
                                                    {l.title}
                                                </label>
                                                <div className="flex-grow-0">
                                                    <div className="form-check form-switch">
                                                        <input id={`regionalLabourDay${l.id}`} onChange={(e) => handleRegionalLabourDayChange(l.id, e.currentTarget.checked)}  defaultChecked={regionalLabourDays.includes(l.id)} disabled={isLoading} className="form-check-input" type="checkbox" />
                                                    </div>
                                                </div>
                                            </div>
                                        </li>)}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {HasModule('timeClock') &&
                <>
                    <div className="col-12 p-3">
                        <h4>
                            Réglages badgeuse
                        </h4>
                    </div>

                    <TimeClockConfig />
                </>
            }
        </>
    )
}

export default AccountingConfig;